<template>
  <div role="application" aria-label="Compact color picker" class="ml-auto vc-compact">
    <ul class="vc-compact-colors d-inline-flex flex-wrap justify-content-around" role="listbox">
      <li
        v-for="c in palette"
        :key="'color-button--' + c.name"
        role="option"
        :aria-label="'color:' + c"
        :aria-selected="selected(c)"
        class="vc-compact-color-item"
        :class="'vc-compact-color-item--' + c.name"
        @click="handleClick(c)"
      >
        <div v-show="selected(c)" class="vc-compact-dot">
          <fa icon="check" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ColorSelector',
  props: {
    palette: {
      type: Array,
      default() {
        return [
          { name: 'red', value: ['red', 'maroon'] },
          { name: 'orange', value: ['orange', 'peach', 'coral'] },
          { name: 'yellow', value: ['yellow', 'mustard', 'gold'] },
          { name: 'green', value: ['green', 'dark-green'] },
          { name: 'aqua', value: ['aqua', 'teal', 'turquoise'] },
          { name: 'blue', value: ['blue', 'navy', 'dark-blue'] },
          {
            name: 'purple',
            value: ['purple', 'aubergine', 'plum', 'violet', 'eggplant']
          },
          { name: 'pink', value: ['pink', 'fuchsia', 'magenta'] },
          {
            name: 'bronze',
            value: ['bronze', 'copper', 'rose-gold', 'brown', 'natural']
          },
          { name: 'silver', value: ['silver', 'grey'] },
          { name: 'white', value: ['white'] },
          { name: 'black', value: ['black'] }
        ]
      }
    },
    inlineFilter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('products', {
      filterColors: state => state.filterColors
    })
  },
  methods: {
    handleClick(c) {
      // if any colors exist then remove all
      // else add all
      let selectedColors = this.filterColors
      if (this.selected(c)) {
        selectedColors = selectedColors.filter(v => {
          return !c.value.includes(v)
        })
      } else {
        selectedColors = [...new Set([...selectedColors, ...c.value])]
      }
      const payload = {
        filterType: 'color',
        filter: selectedColors,
        listProd: !this.inlineFilter
      }
      this.$store.dispatch('products/updateFilters', payload)
    },
    selected(c) {
      // if any member of value is in filterColors then True
      return c.value.some(v => this.filterColors.includes(v))
    }
  }
}
</script>

<style lang="scss">
@media (min-width: 360px) and (max-width: 414px) {
  .vc-compact {
    width: 100%;
    max-width: 100% !important;
    // padding-left: 5px;
  }
  .vc-compact-color-item {
    width: 42px !important;
    height: 42px !important;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
// @media (max-width: 321px) {
//   .btn-circle {
//     width: 1.5rem !important;
//     height: 1.5rem !important;
//     padding: 1.2px 0px !important;
//     border-radius: 0.75rem !important;
//     text-align: center !important;
//     font-size: 0.7rem !important;
//   }
// }
.filter-colors {
  max-width: 170px;
}
@media (max-width: 576px) {
  .filter-colors {
    max-width: 330px;
  }
}
</style>
