<template>
  <div>
    <!-- <b-button v-b-toggle.collapseFilterbar block class="d-md-none m-1 blue bg-white ">
      {{ filterCaret }}
    </b-button> -->
    <div class="dropdown-small d-md-none d-flex justify-content-around border-bottom">
      <!-- <div class="d-md-none d-flex justify-content-around"> -->
      <b-nav class="">
        <b-nav-item
          no-caret
          :class="!inlineSearch ? 'border-right' : ''"
          @click="collapseBtnClick('filter')"
        >
          FILTER BY
          <caret :open="showFilters" />
        </b-nav-item>
        <b-nav-item-dropdown
          v-if="!inlineSearch"
          id="my-nav-dropdown"
          no-caret
          right
          :class="showSort ? 'collapsed' : null"
          @show="showSort = true"
          @hide="showSort = false"
        >
          <template v-slot:button-content>
            <span>
              {{ currentSort }} <caret :open="showSort" />
            </span>
          </template>
          <sort-options @sort-selected="setCurrentSort" />
        </b-nav-item-dropdown>
      </b-nav>
    </div>
    <b-collapse
      id="collapseFilterbar"
      ref="collapseFilterBar"
      v-model="showFilters"
      class="dont-collapse-sm"
      @show="filterShown"
      @hide="filterHidden"
    >
      <div v-if="filterShown" class="dropdown-full">
        <div v-click-outside="vcoConfig" class="filter-group">
          <div :class="containerClass">
            <div class="d-flex align-items-center flex-lg-row flex-column">
              <!-- hello and input -->
              <div class="searchBlock d-flex flex-grow-1">
                <label class="hello mr-sm-2" for="inlineFormCustomSelectPref">hello,</label>
                <div class="flex-grow-1">
                  <search-bar :active="active" :inline-search="inlineSearch" @filter="inlineFilterResults('search-bar')" />
                </div>
              </div>
              <div class="filterBlock flex-grow-1 align-items-center d-flex">
                <!-- size -->
                <div id="size-buttons" class="pb-sm-3 flex-grow-1">
                  <div class="small text-center se-pink">
                    Stud Sizes
                    <span id="size-chart-popover" class="se-dark-grey">
                      <fa :icon="['far', 'question-circle']" />
                    </span>
                    <b-popover target="size-chart-popover" triggers="hover" placement="top" title="Stud Sizes">
                      <size-chart size="md" class="mb-2" />
                      <b-link to="/help/faq" class="pt-2">
                        Find out more
                      </b-link>
                    </b-popover>
                  </div>
                  <div class="d-flex justify-content-around">
                    <span v-for="size in sizeButtons" :key="'filter-size__' + size.caption">
                      <b-btn
                        :id="'filter-size__' + size.caption"
                        :pressed.sync="size.state"
                        size="sm"
                        class="btn-circle transparent-button"
                        @click="sizeClick($event, size)"
                      >
                        {{ size.caption }}
                      </b-btn>
                      <!-- <b-tooltip
                        :target="'filter-size__' + size.caption"
                        triggers="hover"
                        variant="dark"
                        delay="301"
                      >
                        {{ size.tooltip }}
                      </b-tooltip> -->
                    </span>
                  </div>
                </div>
                <!-- colors -->
                <div class="filter-colors">
                  <no-ssr placeholder="Loading...">
                    <color-selector :inline-filter="inlineSearch" />
                  </no-ssr>
                </div>
              </div>
            </div>
            <filter-tags :items="filterBarTags" :inline-search="inlineSearch" @filter="inlineFilterResults('filter-tags')" />
            <inline-filter
              :show="show"
              :inline-products="inlineProducts"
              :inline-search="inlineSearch"
              :count="count"
              :active="active"
              @filter="inlineFilterResults('inline-filter')"
            />
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import normalize from 'json-api-normalizer'
import ColorSelector from '~/components/ColorSelector'
// import InlineSearchItem from '~/components/InlineSearchItem'
import InlineFilter from '~/components/InlineFilter'
import FilterTags from '~/components/FilterTags'
import Caret from '~/components/Caret'
// import SortButton from '~/components/SortButton'
import SearchBar from '~/components/SearchBar'
import SortOptions from '~/components/SortOptions'
import SizeChart from '~/components/SizeChart'
import escapable from '~/mixins/escapable'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    'color-selector': ColorSelector,
    'filter-tags': FilterTags,
    // 'Inline-search-item': InlineSearchItem,
    'inline-filter': InlineFilter,
    'search-bar': SearchBar,
    'sort-options': SortOptions,
    'size-chart': SizeChart,
    caret: Caret
  },
  mixins: [escapable],
  props: {
    inlineSearch: {
      type: Boolean,
      default: false
    },
    openFilters: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sizes: [],
      sizeButtons: [
        { caption: 'XS', state: false, value: 'XS', tooltip: '8mm' },
        { caption: 'S', state: false, value: 'Small', tooltip: '10mm' },
        { caption: 'M', state: false, value: 'Medium', tooltip: '12mm' },
        { caption: 'L', state: false, value: 'Large', tooltip: '15mm' },
        { caption: 'XL', state: false, value: 'XL', tooltip: '17mm' },
        { caption: '2XL', state: false, value: '2XL', tooltip: '19mm' },
        { caption: '3XL', state: false, value: '3XL', tooltip: '21mm' },
        { caption: '4XL', state: false, value: '4XL', tooltip: '23mm' }
      ],
      inlineProducts: null,
      count: 0,
      filterCaret: 'Show filters',
      showFilters: false,
      showSort: false,
      active: false,
      tagItems: [],
      vcoConfig: {
        handler: this.vcoHandler,
        middleware: this.vcoMiddleware,
        events: ['dblclick', 'click'],
        isActive: true
      },
      currentSort: 'SORT BY'
    }
  },
  computed: {
    ...mapState('products', {
      filterText: state => state.filterText,
      filterSize: state => state.filterSize,
      filterColors: state => state.filterColors,
      filterBarTags: state => state.filterBarTags,
      shopUpdated: state => state.shopUpdated
    }),
    ...mapGetters({
      filters: 'products/filters'
    }),
    hasFilters() {
      const hasText = this.filterText.length > 0
      const hasSize = this.filterSize.length > 0
      const hasColor = this.filterColors.length > 0
      return hasText || hasSize || hasColor
    },
    show() {
      return this.hasFilters && this.inlineSearch && this.active
    },
    containerClass() {
      return this.show ? ' filter-group__dropdown-overlay' : ''
    },
    tagContainerStyle() {
      return this.show
        ? 'background-color: white; margin-left: 0.1rem; margin-right: 0.5rem;'
        : ''
    },
    escapableVisible() {
      return this.show
    }
  },
  watch: {
    filterSize() {
      const vals = this.sizeButtons.filter(b => {
        return !this.filterSize.includes(b.value)
      })
      vals.map(v => {
        v.state = false
      })
    },
    filterColors(newVal, oldVal) {
      if (oldVal.length !== newVal.length) {
        this.inlineFilterResults('filterColors')
      }
    },
    shopUpdated() {
      if (this.shopUpdated) {
        this.$store.dispatch('products/setFilterBarTags')
        this.active = true
        this.$store.commit('products/SHOP_UPDATED', false)
      }
    },
    openFilters() {
      this.showFilters = this.openFilters
    }
  },
  mounted() {
    this.showFilters = this.openFilters
    const pressed = this.$store.state.products.filterSize
    this.sizeButtons = this.sizeButtons.map(b => {
      return {
        caption: b.caption,
        state: pressed.includes(b.value),
        value: b.value,
        tooltip: b.tooltip
      }
    })
    this.$store.dispatch('products/setFilterBarTags')
  },
  methods: {
    setTagItems() {
      const keywords = this.filterText.map(k => {
        return { name: k, type: 'text' }
      })
      const colors = this.filterColors.map(c => {
        return { name: c, type: 'color' }
      })
      const sizes = this.filterSize.map(s => {
        return { name: s, type: 'size' }
      })
      this.tagItems = [...keywords, ...sizes, ...colors]
    },
    async setFilterText() {
      const payload = {
        filterType: 'text',
        filter: this.filterString,
        listProd: !this.inlineSearch
      }
      await this.$store.dispatch('products/updateFilters', payload)
      await this.$store.dispatch('products/setFilterBarTags')
      this.inlineFilterResults('setFilterText')
    },
    async sizeClick(e, s) {
      const selected = this.sizeButtons.filter(b => b.state === true)
      this.active = selected && selected.length > 0
      this.sizes = selected.map(s => s.value)
      const payload = {
        filterType: 'size',
        filter: this.sizes,
        listProd: !this.inlineSearch
      }
      await this.$store.dispatch('products/updateFilters', payload)
      this.inlineFilterResults('sizeClick')
    },
    sizeSelected(s) {
      this.active = true
      return this.sizes.includes(s)
    },
    async inlineFilterResults(e) {
      await this.$store.dispatch('products/setFilterBarTags')
      this.active = true
      if (this.inlineSearch) {
        const options = {
          filter: this.filters,
          page: 1,
          perPage: 3,
          sort: 'best_match'
        }
        const data = await this.$api.products.index(options)
        const normed = normalize(data)
        this.count = data.meta.total_count
        this.inlineProducts = normed
      }
    },
    nullOrUndefined(obj) {
      return obj === null || obj === undefined
    },
    productImage(product) {
      if (
        this.nullOrUndefined(product.relationships.images.data) ||
        this.nullOrUndefined(product.relationships.images.data[0])
      ) {
        return ''
      }
      const id = product.relationships.images.data[0].id
      return this.inlineProducts.image ? this.inlineProducts.image[id] : ''
    },
    resetFilters(e, name) {
      if (this.show) {
        this.$store.commit('products/RESET_FILTERS')
      }
      this.active = false
    },
    escapeHandler() {
      this.resetFilters()
      this.active = false
    },
    filterShown() {
      this.filterCaret = 'Hide filters'
    },
    filterHidden() {
      this.active = false
      this.filterCaret = 'Show filters'
    },
    collapseBtnClick(item) {
      if (item === 'filter') {
        this.showFilters = !this.showFilters
        this.showSort = false
      } else {
        this.showFilters = false
        this.showSort = !this.showSort
      }
    },
    vcoHandler(event) {
      this.resetFilters()
    },
    vcoMiddleware(event) {
      return (
        event.target.className !== 'overlay active' &&
        event.target.className !== 'modal fade' &&
        this.active
      )
    },
    setCurrentSort(sort) {
      this.currentSort = sort
    }
  }
}
</script>
<style lang="scss">
#size-buttons {
  min-width: 20rem;
}
</style>
