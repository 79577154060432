<template>
  <span>
    <b-dropdown-item v-for="opt in options" :key="'sortitem__' + opt.sort" @click="setSort(opt)">
      {{ opt.label }} <fa v-if="sort === opt.sort" :icon="['fas', 'check']" size="1x" class="ml-1" />
    </b-dropdown-item>
  </span>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      currentSort: this.sortState,
      options: [
        {
          label: 'Suprise me',
          sort: 'random'
        },
        {
          label: 'Best Match',
          sort: 'best_match'
        },
        {
          label: 'Name: A to Z',
          sort: 'name'
        },
        {
          label: 'Name: Z to A',
          sort: '-name'
        },
        {
          label: 'Size: S to L',
          sort: 'size'
        },
        {
          label: 'Size: L to S',
          sort: '-size'
        },
        {
          label: 'New to Old',
          sort: '-created_at'
        },
        {
          label: 'Old to New',
          sort: 'created_at'
        }
      ]
    }
  },
  computed: {
    sort() {
      return this.$store.state.products.sort
    },
    sortState() {
      if (this.options === undefined) {
        return 'Surprise me'
      }
      return this.options.filter(
        o => this.$store.state.products.sort === o.sort
      )[0].label
    }
  },
  mounted() {
    this.currentSort = this.sortState
  },
  methods: {
    async setSort(sortOpt) {
      await this.$store.dispatch('setRandSeed')
      this.currentSort = sortOpt.label
      this.$emit('sort-selected', sortOpt.label)
      this.$store.commit('products/SET_SORT', sortOpt.sort)
      this.$store.commit('products/SET_PRODUCTS_UPDATING', true)
      await this.$store.dispatch('products/productsList')
      this.$store.commit('products/SET_PRODUCTS_UPDATING', false)
    }
  }
}
</script>
