<template>
  <b-img :src="src" fluid alt="Size chart" @click="onClick" />
</template>
<script>
import constants from './../utils/constants'
import { sizeChartImgUrl } from './../utils/sizeChart'

export default {
  props: {
    size: {
      type: String,
      default: 'sm'
    }
  },
  data() {
    return {
      imgName: constants.sizeChartImgName,
      sizes: constants.sizeChartImgSizes
    }
  },
  computed: {
    cdn() {
      return this.$store.state.cdnUrl
    },
    src() {
      return sizeChartImgUrl(this.cdn, this.size)
    }
  },
  methods: {
    onClick() {
      const urls = {}
      Object.keys(this.sizes).map(s => {
        urls[s] = `${this.cdn}/${this.imgName}${this.sizes[s]}`
      })
      this.$emit('click', urls)
    }
  }
}
</script>
